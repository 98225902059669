(function($) {

    /**
     * Nav mobile toggle.
     */

    $( '.js-toggle-nav-mobile' ).on( 'click', function ( event ) {
        // prevent default
        event.stopPropagation();
        event.preventDefault();
        //
        $( '#nav-mobile' ).slideToggle();
    });


    $(".owl").owlCarousel({
        items:1,
        margin:0,
        padding:0,
        singleItem:true
    });

    $(".content-gallery").owlCarousel({
        items:4,
        margin:0,
        padding:0,
        singleItem:true
    });


    $("#switch_lang").click(function(){
        $('.languages').toggle();
    });


})( jQuery );